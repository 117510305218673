<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <TopHeader />
      <div class="container">
        <div class="py-40 md:py-52">
          <div class="font-bold text-white">
            <h1 class="text-2xl font-semibold md:text-3xl">Bateriku.com:</h1>
            <h1 class="text-4xl font-bold md:text-5xl text-green-1">Bateri Kong?</h1>
          </div>
          <div class="pt-5 text-gray-100">
            <p class="text-lg font-semibold md:text-xl">Kini tiba di Kawasan anda!</p>
            <p class="text-sm leading-tight md:text-base ">Bila- bila masa. Di mana jua. Kami Sentiasa Besama Anda.</p>
          </div>

          <div v-if="!hideEnquiry" class="mt-5 space-y-2 md:flex md:space-y-0 md:space-x-1">
            <p class="py-1 text-center text-white transition duration-200 ease-linear border-2 border-white rounded-md hover:bg-green-1 hover:border-green-1 w-44">
             <a href="tel:+60192152525">Hubungi Kami</a>
            </p>
            <p class="py-1 text-center text-white transition duration-200 ease-linear border-2 border-white rounded-md hover:bg-green-1 hover:border-green-1 w-44">
             <a href="https://wa.me/60192152525">WhatsApp Kami</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TopHeader,
  },
};
</script>
