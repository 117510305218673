<template>
  <div>
    <!-- header -->
    <WaBtn />
    <MainBanner />

    <!-- about us -->
    <div class="py-10 bg-black">
      <div class="container">
        <div class="lg:flex lg:flex-wrap">
          <div class="lg:w-1/2 lg:pr-4">
            <div class="font-semibold text-center text-gray-100 lg:text-left">
              <p class="text-lg">Tentang Kami</p>
              <div class="bg-white w-10 h-0.5 lg:mx-0 mx-auto my-3"></div>
              <p class="text-3xl font-bold text-green-1">Bateriku.com</p>
            </div>
            <div
              class="mt-6 space-y-3 text-sm leading-tight text-center text-gray-100 lg:text-left"
            >
              <p>
                Bateriku.com ditubuhkan dalam industri bateri sejak tahun 2014.
                Sebagai salah satu perkhidmatan bekalan dan penggantian bateri
                kereta yang terkenal di Malaysia, kami telah menyediakan
                pelbagai jenis bateri berkualiti tinggi dengan harga yang
                berpatutan. Antara Jenama bateri yang kami menyediakan termasuk
                Century, Varta, Amaron, Astra dan sebagainya
              </p>
              <p>
                Sebagai pakar bateri profesional, Bateriku.com komited untuk
                memberikan penghantaran yang pantas. Antara Kawasan yang
                meliputi termasuk Bangi, Kajang, Putrajaya, Cyberjaya, Seri
                Kembangan, Serdang, Cheras Selatan, Semenyih dan Puchong.
                Setakat Hari ini, Bateriku.com telah menyediakan perkhidmatan
                atas permintaan kepada lebih daripada 1 juta pelanggan dengan
                menawarkan perkhidmatan penggantian bateri dari A ke Z, termasuk
                pemeriksaan, penghantaran, dan pemasangan. Bateriku.com
                menyediakan sokongan selepas jualan yang paling komprehensif dan
                pentadbiran waranti dengan liputan serata negara.
              </p>
            </div>
          </div>
          <div class="mt-5 md:w-2/3 lg:w-1/2 lg:mx-0 lg:mt-0 md:mx-auto">
            <img src="/images/about-us.png" alt="" class="mx-auto" />
          </div>
        </div>

        <div class="mt-6 lg:mt-10 md:w-2/3 md:mx-auto">
          <div class="p-4 bg-yellow-400 rounded-md">
            <p class="font-medium leading-tight text-center text-gray-800">
              Lokasi : Bangi, Kajang, Putrajaya, Cyberjaya, Seri Kembangan,
              Serdang, Cheras Selatan, Semenyih, Puchong
            </p>
            <p
              class="pt-4 text-2xl font-semibold leading-tight text-center text-black"
            >
              “Bateri Kereta Murah? Hubungi kami”
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- open hours -->
    <div class="py-10">
      <div class="container">
        <div class="md:relative md:pt-28 lg:max-w-3xl lg:mx-auto">
          <div
            class="bg-center bg-no-repeat bg-cover rounded-md"
            style="background-image: url('/images/bg-hours.jpg')"
          >
            <div class="p-5 text-white rounded-md">
              <p class="pb-2 text-lg font-medium">Perlukan Bantuan Segera?</p>
              <p class="text-3xl font-bold leading-tight">
                Hubungi Kami Sekarang!
              </p>
              <p class="pt-4 leading-tight">
                Waktu Operasi : 7 :30 AM- 11 :00 PM, 365 Hari Setahun
              </p>
            </div>

            <div class="absolute right-0 hidden -bottom-8 w-80 md:block">
              <img src="/images/mechanic.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <div class="text-center text-gray-800">
          <p class="text-2xl font-semibold">Kenapa Pilih Kami</p>
          <div class="bg-green-1 w-10 h-0.5 mx-auto my-3"></div>
        </div>

        <div class="mt-7 md:flex md:flex-wrap md:justify-center">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-2/3 pb-5 mx-auto md:mx-0 md:p-2 md:w-1/3"
          >
            <div
              class="p-5 transition duration-200 ease-linear bg-white border border-white shadow-md hover:border-green-1"
            >
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-20 mx-auto"
              />
              <div
                class="pt-4 text-sm font-medium text-center text-gray-700 md:h-20 lg:h-14"
              >
                <p class="leading-tight">{{ item.alt }}</p>
                <p v-show="item.p">{{ item.p }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- product section -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center text-gray-800">
          <p class="text-2xl font-semibold">Brand Kami Ada</p>
          <div class="bg-green-1 w-10 h-0.5 mx-auto my-3"></div>
        </div>

        <div class="mt-6 md:flex md:justify-center">
          <div
            v-for="image in brands"
            :key="image"
            class="w-2/3 mx-auto md:w-1/3 md:mx-0"
          >
            <img :src="'/images/' + image + '.png'" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="py-10">
      <div class="container">
        <div class="text-center text-gray-800">
          <p class="text-2xl font-semibold">Kereta yang Kami Selamatkan!</p>
          <div class="bg-green-1 w-10 h-0.5 mx-auto my-3"></div>
        </div>

        <div class="flex flex-wrap mt-8">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 md:w-1/4">
            <div class="overflow-hidden">
              <img
                :src="'/images/' + image + '.jpg'"
                alt=""
                class="transition duration-200 ease-linear transform hover:scale-110"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="text-center text-gray-800">
          <p class="text-2xl font-semibold">Testimoni Kami</p>
          <div class="bg-green-1 w-10 h-0.5 mx-auto my-3"></div>
        </div>

        <div class="mt-7">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1023: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="image in testimonial" :key="image" class="px-1">
              <img :src="'/images/' + image + '.jpg'" alt="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- footer -->
    <section
      id="enquiry"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-green-1">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <p class="pt-3 text-sm leading-tight text-white">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-10 lg:w-1/2 lg:pr-4">
          <img
            src="/images/main-logo.png"
            alt=""
            class="mx-auto w-44 lg:w-60"
          />
          <div class="pt-4 text-center text-white">
            <p class="font-bold">
              44732 Jalan Abim, Kampung Sungai Ramal Dalam, 43000 Kajang
            </p>
            <p class="pt-6 font-bold">Official Batter Partners:</p>
            <div class="md:flex md:flex-wrap md:justify-center md:mt-4">
              <div
                v-for="image in brands"
                :key="image"
                class="w-2/3 mx-auto md:w-1/4"
              >
                <img :src="'/images/' + image + '.png'" alt="" class="" />
              </div>
            </div>

            <p class="pt-6 font-bold">Our Branches</p>
            <p class="">Bangi, Kajang, Cyberjaya, Putrajaya</p>
          </div>
          <!-- <div class="text-center">
            <h1 class="text-2xl font-bold lg:text-3xl text-gold-1">
              D&S Autoworks Sdn Bhd
            </h1>
            <p class="pt-4 leading-tight text-white">
              No1, Jalan 9/23e, Taman Danau Kota, Jalan Genting Kelang, Batu
              41/2, 53300 Kuala Lumpur
            </p>
            <div class="pt-4">
              <p
                class="text-lg font-semibold text-white transition duration-200 ease-linear transform hover:text-gold-1"
              >
                <a href="tel:+60103333233">Call Us : 010-333 3233</a>
              </p>
            </div>
            <div class="pt-4 text-white">
              <p>Operating Hours</p>
              <p>Monday to Saturday: 9:00 AM – 7:00 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <section class="py-3 bg-black">
      <div class="container mx-auto">
        <p class="text-sm text-center text-white">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue"

export default {
  name: "Home",
  components: {
    MainBanner,
    carousel,
    EnquiryForm,
    WaBtn
  },
  data() {
    return {
      choose_us: [
        {
          image: "choose-1",
          alt: "Respon dan Penghantaran Pantas",
          p: "(dalam 30 mins)",
        },
        {
          image: "choose-2",
          alt: "Penghantaran dan Pemasangan Percuma",
        },
        {
          image: "choose-3",
          alt: "Pemeriksa Percuma",
          p: "(Starter, Alternator & Bateri)",
        },
        {
          image: "choose-4",
          alt: "Luas Kawasan Perkhidmatan",
        },
        {
          image: "choose-5",
          alt: "Perlindungan warranty seluruh negara",
        },
      ],
      brands: ["brand-1", "brand-2", "brand-3", "brand-4","brand-5"],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
      ],
      testimonial: [
        "testi-1",
        "testi-2",
        "testi-3",
        "testi-4",
        "testi-5",
        "testi-6",
      ],
    };
  },
};
</script>
