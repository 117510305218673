<template>
  <div>
    <!-- navigation  -->
    <div
      class="fixed top-0 left-0 z-20 w-full py-4 transition duration-200 ease-linear bg-transparent"
      :class="
        fixtop ? 'bg-black shadow-md z-10' : ' bg-gradient-to-b from-black'
      "
    >
      <div class="container">
        <div class="flex items-center justify-between w-full">
          <div class="md:w-1/2 lg:w-1/3">
            <img src="/images/main-logo.png" alt="" class="w-24 md:w-32" />
          </div>
          <div>
            <!-- <img src="/images/call.png" alt="" class="w-10 md:hidden" /> -->
            <p class="text-xl font-semibold text-right text-white">
              Call Us At <br />
              <a
                href="tel:+60192152525"
                class="block transition duration-200 ease-linear transform hover:text-green-1 hover:scale-105"
                >019-215 2525</a
              >
            </p>
            <p class="text-xl font-semibold text-white">
              <a
                href="tel:+60135843799"
                class="block transition duration-200 ease-linear transform hover:text-green-1 hover:scale-105"
                >013-584 3799</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixtop: false,
    };
  },
  methods: {
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style>
.nav-menu {
  padding-top: 10px;
  position: absolute;
  width: 100%;
}
.open-menu {
  opacity: 1;
  height: 150px;
  display: block;
}
.closed-menu {
  display: none;
}
</style>
